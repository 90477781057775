<template>
  <div>
    <div class="row">
      <div>
        <q-btn v-if="show" flat dense icon="keyboard_arrow_up" @click="show = !show" />
        <q-btn v-if="!show" flat dense icon="keyboard_arrow_down" @click="show = !show" />
      </div>
      <div class="col text-left">
        {{ label }}
      </div>
      <q-tabs v-model="tab">
        <q-tab name="tree" icon="view_list" />
        <q-tab name="mark" icon="check_circle" />
      </q-tabs>
    </div>
    <q-tab-panels v-show="show" v-model="tab" keep-alive>
      <q-tab-panel name="tree">
        <slot></slot>
      </q-tab-panel>
      <q-tab-panel name="mark">
        <q-list v-if="marks">
          <template v-for="[username, mark] of Object.entries(marks)" :key="username">
            <template v-if="username.includes(search)">
              <q-item>
                <q-item-section>
                  <q-item-label>{{ username }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-item-label>{{ mark }} / {{ max }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </template>
        </q-list>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
export default {
  props: {
    label: {},
    marks: {},
    max: Number,
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      tab: "tree",
    };
  },
};
</script>
