<template>
  <div v-if="path && path.length > 0" class="bypass">
    <component :is="'Course' + course.content.type" :course="course.content" :action="action + '/' + path[0]"
      :path="path.slice(1)"></component>
  </div>
  <div v-else>
    <component :is="course.title_tag || 'h1'" class="text-center">{{ course.title }}</component>
    <component v-if="course.subtitle" :is="course.subtitle_tag || 'h2'">{{ course.subtitle }}</component>
    <component v-if="course.content" :is="'Course' + course.content.type" :course="course.content"
      :action="action + '/content'"></component>
  </div>
</template>

<script>
import components from '@/components/Course';

export default {
  components: components,
  props: ["course", "path", "action"],
};
</script>
