// A component that gather notes of a course for all users

<template>
  <component v-if="course.content" :is="`${course.content.type}Corrector`" :course="course.content"
    @marks="$emit('marks', $event)" @max="$emit('max', $event)">
  </component>
</template>

<script>
import correctors from '@/components/Corrector';
import CourseCorrectorNode from '../utils/CourseCorrectorNode.vue';

export default {
  components: {
    ...correctors,
    CourseCorrectorNode,
  },
  props: ["course", "action"],
  emits: ["marks", "max"],
}
</script>
