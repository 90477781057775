<template>
  <div class="q-px-md q-py-sm">
    <div v-if="course.question" class="q-my-sm">
      {{ course.question }}
    </div>
    <div class="row q-gutter-x-lg q-gutter-y-md q-my-sm">
      <template v-for="[i, c] of course.choices.entries()" :key="i">
        <q-radio dense v-model="response" :val="i" :label="`${getOlPrefix(i)}${c}`" :class="[`text-${color[i]}`]"
          :disable="disable" />
      </template>
    </div>
    <div v-if="course.submit_btn" class="q-my-sm print-hide">
      <q-btn @click="submit" label="Valider" class="full-width" :color="submit_color" />
    </div>
  </div>
</template>

<script>
import { genOlPrefix } from '@/utils/ol';

export default {
  props: ["course"],
  data() {
    return {
      response: null,
      submitted: false,
      color: [],
      submit_color: "primary",
    };
  },
  computed: {
    disable() {
      if (this.course.once) {
        return this.response != null;
      }
      if (this.course.submit_once) {
        return this.submitted;
      }
      return false;
    },
  },
  methods: {
    loadResponse() {
      (async () => await this.$api.response.get_last(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course)
      ))().then((r) => {
        if (r != undefined) {
          this.response = r;
          this.submitted = true;
        }
      }).catch((err) => {
        console.error(err);
        this.response = null;
      });
    },
    saveResponse() {
      (async () => await this.$api.response.put(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course),
        this.response
      ))()
        .catch((error) => {
          this.$q.dialog({
            title: error.message,
            message: error.response ? error.response.data.message : "Une erreur est survenue",
            color: 'negative',
          }).onOk(() => {
            window.location.reload();
          });
        });
    },
    getOlPrefix(i) {
      return genOlPrefix(this.course.oltype, ". ", i);
    },
    correct() {
      const response_correct = this.course.correct[this.response];
      const new_color = response_correct ? "positive" : "negative";
      this.color[this.response] = new_color;
      this.submit_color = new_color;
    },
    submit() {
      this.saveResponse();
      this.submitted = true;
      if (this.course.submit_correct) {
        this.correct();
      }
    },
  },
  mounted() {
    this.loadResponse();
  },
  updated() {
    if (this.course.auto_save || this.course.auto_save == undefined) {
      this.saveResponse();
    }
    if (this.course.auto_correct) {
      this.correct();
    }
  },
};
</script>
