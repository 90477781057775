<template>
  <div v-if="path && path.length > 0" class="bypass">
    <component :is="'Course' + course.elements[path[0]].type" :course="course.elements[path[0]]"
      :action="action + '/' + path[0]" :path="path.slice(1)"></component>
  </div>
  <div v-else>
    <div v-for="[i, e] of course.elements.entries()" :key="i">
      <component :is="'Course' + e.type" :course="e" :action="action + '/' + i"></component>
    </div>
  </div>
</template>

<script>
import components from '@/components/Course';

export default {
  components: components,
  props: ["course", "path", "action"],
};
</script>
