<template>
  <div class="row q-gutter-md">
    <q-btn @click="send('left')" label="Left" class="col" />
    <div class="col column q-gutter-md">
      <q-btn @click="send('up')" label="Up" class="" />
      <q-btn @click="send('down')" label="Down" class="" />
    </div>
    <q-btn @click="send('right')" label="Right" class="col" />
  </div>
</template>

<script>
// send key controls to socket
export default {
  methods: {
    send(key) {
      this.$api.socket.emit('message', key);
    },
  },
};
</script>