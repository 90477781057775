<template>
  <div class="text-center">
    <svg :width="course.width" xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${course.w} ${course.h}`">
      <defs>
        <template v-for="[i, q] of course.questions.entries()" :key="i">
          <g :id="`${uuid}-${i}-undefined`" v-html="replaceOids(q.svg)"></g>
          <g :id="`${uuid}-${i}-correct-true`" v-html="replaceOids(q.correct_true)"></g>
          <g :id="`${uuid}-${i}-correct-false`" v-html="replaceOids(q.correct_false)"></g>
          <template v-for="[j, choice] of q.choices.entries()" :key="j">
            <g :id="`${uuid}-${i}-${j}`" v-html="replaceOids(choice.svg)"></g>
          </template>
        </template>
      </defs>
      <g>
        <image :href="src" x="0" y="0" :width="course.w" :height="course.h" />
      </g>
      <g>
        <template v-for="[i, q] of course.questions.entries()" :key="i">
          <use :href="`#${uuid}-${i}-${response[i]}`" :x="q.x" :y="q.y" @click="startPick(i)" />
        </template>
      </g>
      <g>
        <template v-for="[i, q] of course.questions.entries()" :key="i">
          <use v-if="correct_response[i] != undefined" :href="`#${uuid}-${i}-correct-${correct_response[i]}`" :x="q.x"
            :y="q.y" @click="startPick(i)" />
        </template>
      </g>
      <g>
        <rect v-if="pick != null" opacity="0.7" fill="white" x="0" y="0" :width="course.w" :height="course.h"
          @click="pick = null" />
      </g>
      <g>
        <template v-for="[i, q] of course.questions.entries()" :key="i">
          <template v-if="pick == i">
            <template v-for="j in q.choices.length" :key="j">
              <g :transform="`translate(${choiceX(i, j - 1)}, ${choiceY(i, j - 1)})`">
                <use @click="endPick(i, j)" :href="`#${uuid}-${i}-${j - 1}`" :transform="`scale(${q.scale || 0.5})`" />
              </g>
            </template>
          </template>
        </template>
      </g>
    </svg>
    <q-btn v-if="course.submit_btn" @click="submit()" label="Valider" class="full-width print-hide"
      :color="submit_color" />
  </div>
</template>

<style scoped>
@media screen {
  svg {
    width: v-bind("course.width");
  }
}

@media print {
  svg {
    width: v-bind("course.print_width");
  }
}
</style>

<script>
export default {
  props: ["course"],
  data() {
    return {
      response: [],
      submitted: false,
      src: null,
      img_src: [],
      pick: null,
      uuid: Math.random().toString(36).substring(7),
      submit_color: 'primary',
      correct_response: [],
    };
  },
  computed: {
    disable() {
      if (this.course.submit_once) {
        return this.submitted;
      }
      return false;
    },
  },
  methods: {
    loadResponse() {
      (async () => await this.$api.response.get_last(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course)
      ))().then((r) => {
        if (r) {
          this.response = r;
          this.submitted = true;

          // convert null to undefined
          for (let i = 0; i < this.response.length; i++) {
            if (this.response[i] == null) {
              this.response[i] = undefined;
            }
          }
        }
      }).catch((err) => {
        console.error(err);
        this.response = [];
      });
    },
    saveResponse() {
      (async () => await this.$api.response.put(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course),
        this.response
      ))()
        .catch((error) => {
          this.$q.dialog({
            title: error.message,
            message: error.response ? error.response.data.message : "Une erreur est survenue",
            color: 'negative',
          }).onOk(() => {
            window.location.reload();
          });
        });
    },
    fetchImageUrl() {
      // get url from api
      this.$api.object.url(this.course.src).then((url) => {
        this.src = url;
      }).catch(error => {
        console.error(error);
      });
      // get others urls
      for (const [i, oid] of this.course.oids.entries()) {
        this.$api.object.url(oid).then((url) => {
          this.img_src[i] = url;
        }).catch(error => {
          console.error(error);
        });
      }
    },
    choiceX(i, j) {
      const x = this.course.questions[i].x;
      const l = this.course.questions[i].choices.length;
      const _2pi = Math.PI * 2;
      const r = (this.course.questions[i].range || 1) * _2pi;
      const o = (this.course.questions[i].offset || 0) * _2pi;
      return x + (this.course.questions[i].rx || 10) * Math.cos(o + (r * j / l));
    },
    choiceY(i, j) {
      const y = this.course.questions[i].y;
      const l = this.course.questions[i].choices.length;
      const _2pi = Math.PI * 2;
      const r = (this.course.questions[i].range || 1) * _2pi;
      const o = (this.course.questions[i].offset || 0) * _2pi;
      return y + (this.course.questions[i].ry || 10) * Math.sin(o + (r * j / l));
    },
    replaceOids(svg) {
      if (!svg) {
        return null;
      }
      // replace "${<oid>}" in svg by the corresponding url
      return svg.replace(/\$\{([0-9a-f]+)\}/g, (match, oid) => {
        const i = this.course.oids.findIndex((o) => o === oid);
        return i !== -1 ? this.img_src[i] : match;
      }) || null;
    },
    correct() {
      this.submit_color = 'positive';
      // pour chaque question
      for (const [i, q] of this.course.questions.entries()) {
        // si la réponse est incorrecte
        if (this.response[i] != undefined && !q.choices[this.response[i]].correct) {
          this.correct_response[i] = false;
          this.submit_color = 'negative';
        }
        else if (this.response[i] == undefined) {
          this.correct_response[i] = false;
          this.submit_color = 'negative';
        }
        else {
          this.correct_response[i] = true;
        }
      }
    },
    submit() {
      this.saveResponse();
      this.submitted = true;
      if (this.course.submit_correct) {
        this.correct();
      }
    },
    startPick(i) {
      if (!this.disable) {
        this.pick = i;
      }
    },
    endPick(i, j) {
      this.response[i] = j - 1;
      this.pick = null;
      if (this.course.auto_save) {
        this.saveResponse();
      }
    },
  },
  mounted() {
    this.fetchImageUrl();
    this.loadResponse();
  }
};
</script>
