<template>
  <div class="column q-gutter-md">
    <q-file accept=".jpg, image/*" v-model="file" @update:model-value="uploadImage"
      :label="c.src || 'Ajouter un fichier'" />
    <div class="column">
      <q-input v-model="c.width" filled dense label="width" />
      <q-input v-model="c.print_width" filled dense label="print width" />
      <q-input v-model="c.w" filled dense label="viewBox.w" />
      <q-input v-model="c.h" filled dense label="viewBox.h" />
    </div>
    <div class="column">
      <q-checkbox v-model="c.auto_save" label="Sauvegarde Auto" @click="$emit('update:course', c)" />
    </div>
    <div class="column">
      <q-checkbox v-model="c.submit_btn" label="Bouton de validation" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.submit_correct" label="Correction à la validation" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.submit_once" label="Valider une seule fois" @click="$emit('update:course', c)" />
    </div>
    <div>
      <file-uploader v-model:oids="c.oids" @update:oids="c.oids = $event" />
    </div>
    <div class="column q-gutter-md">
      <div v-for="[i, q] of c.questions.entries()" :key="i" class="column q-gutter-md">
        <div class="column">
          <div class="row">
            <q-btn flat dense icon="delete" @click="c.questions.splice(i, 1); $emit('update:course', c)" />
            <q-input v-model.number="q.x" filled dense label="x" type="number" class="col" />
            <q-input v-model.number="q.y" filled dense label="y" type="number" class="col" />
            <q-input v-model.number="q.rx" filled dense label="rx" type="number" class="col" />
            <q-input v-model.number="q.ry" filled dense label="ry" type="number" class="col" />
            <q-input v-model.number="q.scale" filled dense label="scale" type="number" class="col" />
            <q-input v-model.number="q.range" filled dense label="range" type="number" class="col" />
            <q-input v-model.number="q.offset" filled dense label="offset" type="number" class="col" />
          </div>
          <q-input v-model="q.svg" filled dense label="svg" class="col" type="textarea" autogrow />
          <q-input v-model="q.correct_true" filled dense label="Correct" class="col" type="textarea" autogrow />
          <q-input v-model="q.correct_false" filled dense label="Incorrect" class="col" type="textarea" autogrow />
        </div>
        <div class="column q-px-md">
          <div v-for="[j, c] of q.choices.entries()" :key="j" class="row">
            <q-btn flat dense icon="delete" @click="q.choices.splice(j, 1);" />
            <q-input v-model="c.svg" filled dense label="svg" class="col" type="textarea" autogrow />
            <q-checkbox v-model="c.correct" label="Correct" />
          </div>
          <q-btn flat dense icon="add" @click="q.choices.push({}); $emit('update:course', c)">
            Ajouter un choix
          </q-btn>
        </div>
      </div>
      <q-btn flat dense icon="add" @click="c.questions.push({ choices: [] }); $emit('update:course', c)">
        Ajouter une question
      </q-btn>
    </div>
    <q-scroll-area style="height: 200px; width: 100%;">
      <svg :width="course.width" xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${course.w} ${course.h}`">
        <defs>
          <template v-for="[i, q] of c.questions.entries()" :key="i">
            <g :id="`${uuid}-${i}-undefined`" v-html="replaceOids(q.svg)"></g>
            <template v-for="[j, choice] of q.choices.entries()" :key="j">
              <g :id="`${uuid}-${i}-${j}`" v-html="replaceOids(choice.svg)"></g>
            </template>
          </template>
        </defs>
        <g>
          <image :href="src" x="0" y="0" :width="c.w" :height="c.h" />
        </g>
        <g>
          <template v-for="[i, q] of c.questions.entries()" :key="i">
            <use :href="`#${uuid}-${i}-${response[i]}`" :x="q.x" :y="q.y" @click="pick = i" />
          </template>
        </g>
        <g>
          <rect v-if="pick != null" opacity="0.7" fill="white" x="0" y="0" :width="c.w" :height="c.h"
            @click="pick = null" />
        </g>
        <g>
          <template v-for="[i, q] of c.questions.entries()" :key="i">
            <template v-if="pick == i">
              <template v-for="j in q.choices.length" :key="j">
                <g :transform="`translate(${choiceX(i, j - 1)}, ${choiceY(i, j - 1)})`">
                  <use @click="response[i] = j - 1; pick = null;" :href="`#${uuid}-${i}-${j - 1}`"
                    :transform="`scale(${q.scale || 0.5})`" />
                </g>
              </template>
            </template>
          </template>
        </g>
      </svg>
    </q-scroll-area>
  </div>
</template>

<script>
import FileUploader from "@/components/utils/FileUploader.vue";

export default {
  components: {
    FileUploader
  },
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
      src: null,
      img_src: [],
      file: null,
      pick: null,
      uuid: Math.random().toString(36).substring(7),
      response: [],
    };
  },
  created() {
    if (!this.c.questions) {
      this.c.questions = [];
    }
    if (!this.c.oids) {
      this.c.oids = [];
    }
  },
  mounted() {
    this.fetchImageUrl();
  },
  methods: {
    updateChoice(i, value) {
      this.c.choices[i] = value;
      this.$emit('update:course', this.c);
    },
    fetchImageUrl() {
      // get url from api
      this.$api.object.url(this.c.src).then((url) => {
        this.src = url;
      }).catch(error => {
        console.error(error);
      });
      // get others urls
      for (const [i, oid] of this.c.oids.entries()) {
        this.$api.object.url(oid).then((url) => {
          this.img_src[i] = url;
        }).catch(error => {
          console.error(error);
        });
      }
    },
    uploadImage(file) {
      this.$api.object.putFile(file).then((oid) => {
        this.c.src = oid;
        this.$emit('update:course', this.c);
        this.fetchImageUrl();
      }).catch(error => {
        console.error(error);
      });
    },
    choiceX(i, j) {
      const x = this.c.questions[i].x;
      const l = this.c.questions[i].choices.length;
      const _2pi = Math.PI * 2;
      const r = (this.c.questions[i].range || 1) * _2pi;
      const o = (this.c.questions[i].offset || 0) * _2pi;
      return x + (this.c.questions[i].rx || 10) * Math.cos(o + (r * j / l));
    },
    choiceY(i, j) {
      const y = this.c.questions[i].y;
      const l = this.c.questions[i].choices.length;
      const _2pi = Math.PI * 2;
      const r = (this.c.questions[i].range || 1) * _2pi;
      const o = (this.c.questions[i].offset || 0) * _2pi;
      return y + (this.c.questions[i].ry || 10) * Math.sin(o + (r * j / l));
    },
    replaceOids(svg) {
      if (!svg) {
        return null;
      }
      // replace "${<oid>}" in svg by the corresponding url
      return svg.replace(/\$\{([0-9a-f]+)\}/g, (match, oid) => {
        const i = this.c.oids.findIndex((o) => o === oid);
        return i !== -1 ? this.img_src[i] : match;
      }) || null;
    }
  },
};
</script>
