<template>
  <div class="q-pa-md">
    <q-form @submit="saveResponse">
      <div v-for="i of response.keys()" :key="i" class="q-my-sm">
        <q-input v-model="response[i]" placeholder="Saisissez votre proposition" outlined dense>
          <template v-slot:append>
            <q-btn icon="delete" flat color="negative" @click="removeItem(i)" />
          </template>
        </q-input>
      </div>
      <div class="q-my-sm">
        <q-btn label="Ajouter une proposition" icon="add" @click="addItem" color="primary" />
      </div>
      <div class="q-my-sm">
        <q-btn type="submit" label="Valider" color="green" />
      </div>
    </q-form>
  </div>
</template>

<script>
export default {
  props: ["course"],
  data() {
    return {
      response: [""],
    };
  },
  methods: {
    addItem() {
      this.response.push("");
    },
    removeItem(index) {
      this.response.splice(index, 1);
    },
    loadResponse() {
      (async () => await this.$api.response.get_last(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course)
      ))().then((r) => {
        if (r) {
          this.response = r;
        }
      });
    },
    saveResponse() {
      (async () => await this.$api.response.put(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course),
        this.response
      ))();
    }
  },
  mounted() {
    this.loadResponse();
  }
};
</script>
