<template>
  <div v-if="element">
    <div id="blocklyDiv" style="height: 480px; width: 600px;"></div>
    <q-btn @click="generateCode" label="Generate code" />
  </div>
</template>

<script>
// Import Blockly core.
import * as Blockly from 'blockly/core';
// Import the default blocks.
// eslint-disable-next-line
import * as libraryBlocks from 'blockly/blocks';
// Import a generator.
// eslint-disable-next-line
import { javascriptGenerator } from 'blockly/javascript';
// Import a message file.
import * as Fr from 'blockly/msg/fr';

Blockly.setLocale(Fr);

export default {
  props: {
    element: {
      type: Object,
      required: true  // L'objet complet est requis
    }
  },
  data() {
    return {
      workspace: null
    };
  },
  methods: {
    generateCode() {
      // Generate JavaScript code and display it.
      var code = javascriptGenerator.workspaceToCode(this.workspace);
      alert(code);
    }
  },
  mounted() {
    // Create a div to hold the Blockly workspace.
    this.workspace = Blockly.inject(document.getElementById('blocklyDiv'), {
      toolbox: {
        // There are two kinds of toolboxes. The simpler one is a flyout toolbox.
        kind: 'flyoutToolbox',
        // The contents is the blocks and other items that exist in your toolbox.
        contents: [
          {
            kind: 'block',
            type: 'controls_if'
          },
          {
            kind: 'block',
            type: 'controls_whileUntil'
          }
          // You can add more blocks to this array.
        ]
      }
    });
  }
};
</script>
