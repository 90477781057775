import user from './user';
import repository from './repository';
import course from './course';
import object from './object';
import response from './response';
import group from './group';
import socket from './socket';

export default {
  user,
  repository,
  course,
  object,
  response,
  group,
  socket,
};
