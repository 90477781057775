import client from './client';

export default {
  async get(ns, name) {
    return await client.get(`/api/repository/${ns}/${name}`);
  },
  async put(repo) {
    return await client.post(`/api/repository/${repo.namespace}/${repo.name}`, repo);
  },
  async delete(ns, name) {
    return await client.delete(`/api/repository/${ns}/${name}`);
  },
  async get_all() {
    return await client.get('/api/repositories');
  }
}
