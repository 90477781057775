<template>
  <div class="row items-center print-hide course q-my-lg" id="path">
    <router-link :to="`/course/${namespace}/${name}/${ref}`">
      <q-icon name="home" />
    </router-link>
  </div>
  <div class="course q-my-lg">
    <component v-if="course" :is="'Course' + course.type" :course="course" :action="action" :path="path">
    </component>
  </div>
</template>

<script>
import components from '@/components/Course';  // Map des composants dynamiques

export default {
  components: components,
  data() {
    return {
      namespace: this.$route.params.namespace,
      name: this.$route.params.name,
      ref: this.$route.params.ref,
      path: this.$route.params.path,
      course: null,
    };
  },
  computed: {
    action() {
      return "/course/" + this.namespace + "/" + this.name + "/master";
    }
  },
  mounted() {
    this.$api.course.get(this.namespace, this.name, this.ref)
      .then(course => {
        this.course = course;
      })
      .catch(error => {
        console.error(error);
      });
  },
  watch: {
    '$route.params.path': {
      immediate: true,
      handler(newPath) {
        this.path = newPath;
      }
    }
  }
};
</script>

<style>
h1 {
  font-size: calc(4.8mm + 3.0vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

h2 {
  font-size: calc(4.4mm + 2.4vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

h3 {
  font-size: calc(4.0mm + 2.0vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

h4 {
  font-size: calc(3.6mm + 1.6vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

h5 {
  font-size: calc(3.4mm + 1.4vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

h6 {
  font-size: calc(3.2mm + 1.2vw);
  line-height: 1.0em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
}

.course>* {
  font-size: calc(3.0mm + 1.0vw);
  line-height: 1.0em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
</style>