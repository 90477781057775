<template>
  <q-form @submit="saveResponse" :class="{ row: course.row, 'q-gutter-x-md': true }">
    <div v-if="course.show_question || course.show_question == undefined" class="col-auto q-my-sm">
      {{ course.question }}
    </div>
    <q-input type="textarea" :input-style="{ minHeight: `${course.answer_lines * 1.8}em` }" v-model="response" outlined
      dense autogrow @blur="saveResponse" @keydown.enter="saveResponse" :counter="course.counter"
      :maxlength="course.max_length" class="col" />
    <q-btn v-if="course.submit_btn" class="full-width q-my-sm" type="submit" label="Valider" color="green" />
  </q-form>
</template>

<script>
export default {
  props: ["course"],
  data() {
    return {
      response: null,
    };
  },
  methods: {
    loadResponse() {
      (async () => await this.$api.response.get_last(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course)
      ))().then((r) => {
        if (r != undefined) {
          this.response = r;
        }
      }).catch((err) => {
        console.error(err);
        this.response = null;
      });
    },
    saveResponse() {
      (async () => await this.$api.response.put(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course),
        this.response
      ))();
    }
  },
  mounted() {
    this.loadResponse();
  },
};
</script>
