// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/Page/LoginPage.vue';  // Page de login
import STULoginPage from './components/Page/STULoginPage.vue';  // Page de login pour les étudiants
import HomePage from './components/Page/HomePage.vue'; // Page principale
import RepoPage from './components/Page/RepoPage.vue'; // Composant pour visualiser un dépot
import CreateRepoPage from './components/Page/CreateRepoPage.vue'; // Page pour créer un dépot
import CoursePage from './components/Page/CoursePage.vue';
import UserAdminPage from './components/Page/UserAdminPage.vue'; // Page d'administration des utilisateurs
import ControlPage from './components/ControlPage.vue'; // Page de contrôle

import api from './api/dummy';  // Importer l'API dummy

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: { requiresAuth: true }  // Protéger cette route
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/stu/login',
    component: STULoginPage
  },
  {
    path: '/repo/:namespace/:name',
    component: RepoPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/repo/create',
    component: CreateRepoPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/course/:namespace/:name/:ref/:path(.*)*',
    component: CoursePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/users',
    component: UserAdminPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/control',
    component: ControlPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      // Requête pour vérifier si l'utilisateur est authentifié via le cookie
      const user = await api.user.me();
      if (user.username) {
        next(); // L'utilisateur est authentifié
      } else {
        next("/login"); // Rediriger vers la page de login
      }
    } catch (error) {
      next("/login"); // En cas d'erreur, rediriger vers login
    }
  } else {
    next(); // Si la route ne nécessite pas d'authentification, continuer
  }
});

export default router;
