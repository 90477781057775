// a dummy user api for testing purpose

const users = {
  "admin": {
    username: "admin",
    password: "admin",
    groups: ["admin"],
  },
  "lool": {
    username: "lool",
    password: "lool",
    groups: [],
  },
};

let me = "admin";

// function to remove password from user object
function rmpw(user) {
  const { password, ...rest } = user;
  password;
  return rest;
}

export default {
  async me() {
    return rmpw(users[me]);
  },
  async login(username, password) {
    const user = await users[username];
    if (user && user.password === password) {
      me = username;
    }
    else {
      throw new Error("Invalid username or password");
    }
  },
  async logout() {
    me = null;
  },
  async get_all() {
    return Object.values(users).map(rmpw);
  },
  async get(username) {
    return rmpw(users[username]);
  },
  async put(user) {
    users[user.username] = { ...user };
  },
  async delete(username) {
    delete users[username];
  }
}
