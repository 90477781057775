<template>
  <div class="column">
    <q-input v-model="c.content" @update:model-value="$emit('update:course', c)" filled autogrow dense
      type="textarea" />
  </div>
</template>

<script>
export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
};
</script>
