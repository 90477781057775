<template>
  <div class="q-my-xl">
    <q-card>
      <q-form @submit="login" class="q-gutter-md">
        <q-card-section>
          <div class="text-h6">Se connecter</div>
        </q-card-section>

        <q-card-section>
          <q-input filled v-model="username" label="Nom d'utilisateur" />
          <q-input filled v-model="password" label="Mot de passe" type="password" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="Login" type="submit" color="primary" />
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  created() {
    // Lire les paramètres GET à partir de l'URL
    const query = this.$route.query;
    if (query.username && query.password) {
      this.username = query.username;
      this.password = query.password;
      this.login(); // Essayer de se connecter automatiquement
    }
  },
  methods: {
    login() {
      this.$api.user.login(this.username, this.password)
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => {
          this.$q.dialog({
            title: error.message,
            message: error.response ? error.response.data.message : "Une erreur est survenue",
            color: 'negative',
            persistent: true,
          });
        });
    }
  }
}
</script>
