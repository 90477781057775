import client from './client';

export default {
  async get(namespace, name, ref) {
    return await client.get(`/api/course/${namespace}/${name}/${ref}`);
  },
  async put(namespace, name, ref, course) {
    return await client.post(`/api/course/${namespace}/${name}/${ref}`, course);
  },
}
