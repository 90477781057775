<template>
  <div class="q-my-xl">
    <h4>Créer un dépôt</h4>
    <q-form class="column q-gutter-md" @submit="createRepo">
      <div class="row items-center">
        <div class="col-2">
          <q-select filled color="secondary" v-model="namespace" :options="nsopt" label="Espace"></q-select>
        </div>
        <div class="col-1 text-center">/</div>
        <div class="col-2">
          <q-input filled color="secondary" v-model="name" label="Nom" />
        </div>
      </div>
      <q-input v-model="description" label="Description" />
      <q-btn type="submit" label="Créer" color="green" />
    </q-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nsopt: [],
      namespace: null,
      name: '',
      description: ''
    };
  },
  async mounted() {
    this.nsopt = [(await this.$api.user.me()).username]
    this.namespace = this.nsopt[0];
  },
  methods: {
    createRepo() {
      this.$api.repository.put({
        namespace: this.namespace,
        name: this.name,
        description: this.description
      })
        .then(() => {
          this.$router.push('/');
        })
        .catch(error => {
          console.error('Erreur de création du dépôt', error);
        });
    }
  }
}
</script>