import client from './client';

async function put(data) {
  // data is an arraybuffer, send as binary
  return (await client.post(`/api/object`, data, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  })).oid;
}


export default {
  async url(oid) {
    return `${process.env.VUE_APP_BACKEND_URL}/api/object/${oid}`;
  },
  async get(oid) {
    return await client.get(`/api/object/${oid}`);
  },
  put,
  async putFile(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        put(e.target.result)
          .then((res) => {
            resolve(res);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
