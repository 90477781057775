<template>
  <div>
    <div class="row">
      <div>
        <q-btn v-if="show" flat dense icon="keyboard_arrow_up" @click="show = !show" />
        <q-btn v-if="!show" flat dense icon="keyboard_arrow_down" @click="show = !show" />
      </div>
      <div class="col text-left">
        {{ label }}
      </div>
      <div class="row">
        <!-- cut -->
        <q-btn flat dense icon="content_cut" @click="$emit('cut')" />
        <!-- copy -->
        <q-btn flat dense icon="content_copy" @click="$emit('copy')" />
        <!-- delete -->
        <q-btn flat dense icon="delete" @click="$emit('delete')" />
      </div>
    </div>
    <div v-show="show" class="row">
      <div class="column">
        <q-btn size="xs" flat dense icon="none" />
      </div>
      <div class="col">
        <slot class="col"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label"],
  emits: ["delete", "copy", "cut"],
  data() {
    return {
      show: true,
    };
  },
};
</script>
