import { defineAsyncComponent } from 'vue';

const requireComponent = require.context(
  '.',  // Le chemin relatif du dossier des composants
  false, // Ne pas chercher dans les sous-dossiers
  /[A-Z]\w+\.vue$/  // Correspond aux fichiers .vue avec une majuscule au début
);

export default Object.fromEntries(
  requireComponent.keys().map(fileName => {
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');

    // Utilisation de defineAsyncComponent pour charger dynamiquement les composants
    const asyncComponent = defineAsyncComponent(() =>
      import(`./${fileName.replace('./', '')}`)
    );

    return [componentName, asyncComponent];
  })
);
