// File: CourseDirectory.vue
// A component that show a list of links to course elements.

<template>
  <div v-if="path && path.length > 0" class="bypass">
    <Teleport to="#path">
      <q-icon name="chevron_right" />
      <router-link :to="action + '/' + path[0]">
        {{ course.elements[path[0]].title }}
      </router-link>
    </Teleport>
    <component :is="'Course' + course.elements[path[0]].content.type" :course="course.elements[path[0]].content"
      :action="action + '/' + path[0]" :path="path.slice(1)"></component>
  </div>
  <div v-else>
    <q-list bordered separator>
      <q-item v-for="[i, e] of course.elements.entries()" :key="e.title" clickable :to="action + '/' + i" v-ripple
        exact>
        <q-item-section>{{ e.title }}</q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import components from '@/components/Course';

export default {
  components: components,
  props: ["course", "action", "path"]
}
</script>
