<template>
  <div class="column">
    <q-input v-model="c.id" @update:model-value="$emit('update:course', this.c)" filled dense label="ID de la vidéo" />
    <q-input v-model="c.title" @update:model-value="$emit('update:course', this.c)" filled dense label="Titre" />
    <q-input v-model="c.width" @update:model-value="$emit('update:course', this.c)" filled dense label="Largeur" />
    <q-scroll-area style="height: 200px; width: 100%;">
      <div class="q-mx-auto print-hide" :style="{ width: course.width }">
        <q-responsive :ratio="16 / 9">
          <iframe :src="iframe_src" :title="course.title" frameborder="0"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </q-responsive>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
  computed: {
    iframe_src() {
      return `https://www.youtube.com/embed/${this.course.id}`;
    },
    img_src() {
      return `https://i1.ytimg.com/vi/${this.course.id}/0.jpg`;
    },
  },
};
</script>
