
import './styles/quasar.sass'
import lang from 'quasar/lang/fr.js'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog,
  },
  lang: lang
}
