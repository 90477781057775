// a dummy repository api for testing purpose

let repositories = [
  {
    "namespace": "admin",
    "name": "test",
    "description": "a dummy repository",
  },
];

export default {
  async get(ns, name) {
    return repositories.find(r => r.namespace === ns && r.name === name);
  },
  async put(repo) {
    if (repositories.find(r => r.namespace === repo.namespace && r.name === repo.name)) {
      // replace
      repositories = repositories.map(r => r.namespace === repo.namespace && r.name === repo.name ? repo : r);
    }
    else {
      // create
      repositories.push(repo);
    }
  },
  async get_all() {
    return [...repositories];
  }
}
