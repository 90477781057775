// provide a clipboard for the frontend that holds courses

export default {
  get() {
    return JSON.parse(sessionStorage.getItem("clipboard"));
  },
  set(course) {
    sessionStorage.setItem("clipboard", JSON.stringify(course));
  },
  has() {
    return !!sessionStorage.getItem("clipboard");
  },
};
