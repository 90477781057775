import client from './client';

export default {
  async me() {
    return await client.get('/api/me');
  },
  async login(username, password) {
    return await client.get('/login', { params: { username, password } });
  },
  async stu_login(room, terminal, password) {
    return await client.get('/stu/login', { params: { room, terminal, password } });
  },
  async logout() {
    return await client.get('/logout');
  },
  async get_all() {
    return await client.get('/users');
  },
  async get(username) {
    return await client.get(`/user/${username}`);
  },
  async put(user) {
    return await client.post(`/user/${user.username}`, user);
  },
  async delete(username) {
    return await client.delete(`/user/${username}`);
  }
}
