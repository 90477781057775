<!--
  User Admin Page vue component

  This page display all the existing users, their groups, and allow to create a new user.
-->

<template>
  <div class="q-my-xl">
    <q-card>
      <q-card-section>
        <div class="text-h6">Utilisateurs</div>
      </q-card-section>

      <q-card-section class="column q-gutter-md">
        <q-list bordered separator>
          <q-item v-for="user in users" :key="user.username">
            <q-item-section>
              <q-item-label>
                <div class="row">
                  <div class="col-2">
                    <q-icon name="person" />
                    {{ user.username }}
                  </div>
                  <div class="col q-gutter-md">
                    <div class="row q-gutter-xs">
                      <q-chip v-for="group in user.groups" :key="group" color="primary" size="md" removable
                        @remove="group_remove_user(group, user.username)">
                        {{ group }}
                      </q-chip>
                    </div>
                    <q-input v-model="user.new_group" rounded outlined dense placeholder="Ajouter un groupe"
                      @keyup.enter="group_add_user(group, user.username)">
                      <template v-slot:append>
                        <q-btn color="positive" rounded flat size="xs" icon="add"
                          @click="group_add_user(user.new_group, user.username)" />
                      </template>
                    </q-input>
                  </div>
                  <div class="col-1">
                    <q-btn icon="delete" flat color="negative" @click="delete_user(user.username)" />
                  </div>
                </div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-form v-on:submit="add_user">
          <div class="row">
            <q-input class="col" v-model="newUser.username" placeholder="Nom d'utilisateur" outlined dense />
            <q-input class="col" type="password" v-model="newUser.password" placeholder="Mot de passe" outlined dense />
            <q-btn class="col" type="submit" label="Ajouter" color="primary" />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      newUser: {
        username: '',
        password: ''
      }
    };
  },
  async mounted() {
    await this.fetch_users();
  },
  methods: {
    async fetch_users() {
      // call backend to get all users
      try {
        const users = await this.$api.user.get_all();
        this.users = users;
      } catch (error) {
        console.error('Erreur lors du chargement des utilisateurs', error);
      }
    },
    async add_user() {
      // call backend to create a new user
      try {
        await this.$api.user.put({
          username: this.newUser.username,
          password: this.newUser.password,
          groups: [],
        });
        await this.fetch_users(); // refresh users list
      } catch (error) {
        console.error('Erreur lors de la création de l\'utilisateur', error);
      }
    },
    async delete_user(username) {
      // call backend to delete a user
      try {
        await this.$api.user.delete(username);
        await this.fetch_users(); // refresh users list
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'utilisateur', error);
      }
    },
    group_add_user(group, username) {
      // call backend to add a user to a group
      this.$api.group.add_user(group, username).then(() => {
        this.fetch_users(); // refresh users list
      }).catch((error) => {
        console.error('Erreur lors de l\'ajout de l\'utilisateur au groupe', error);
      });
    },
    group_remove_user(group, username) {
      // call backend to remove a user from a group
      this.$api.group.remove_user(group, username).then(() => {
        this.fetch_users(); // refresh users list
      }).catch((error) => {
        console.error('Erreur lors de la suppression de l\'utilisateur du groupe', error);
      });
    },
  }
};
</script>