<template>
  <div class="column q-gutter-md">
    <div class="row">
      <q-select class="col-1" v-model="c.title_tag" @update:model-value="$emit('update:course', c)" filled dense
        label="Type de titre" :options="['h1', 'h2', 'h3', 'h4', 'h5', 'h6']" />
      <q-input class="col" v-model="c.title" @update:model-value="$emit('update:course', c)" filled dense
        placeholder="Title" />
    </div>
    <div class="row">
      <q-select class="col-1" v-model="c.subtitle_tag" @update:model-value="$emit('update:course', c)" filled dense
        label="Type de titre" :options="['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']" />
      <q-input class="col" v-model="c.subtitle" @update:model-value="$emit('update:course', c)" filled dense
        placeholder="Subtitle" />
    </div>
    <div class="column">
      <course-editor-node v-if="c.content" :label="c.content.type" @delete="deleteElement()"
        @copy="$clipboard.set(c.content)" @cut="$clipboard.set(c.content); deleteElement()">
        <component :is="c.content.type + 'Editor'" :course="c.content" @update:course="updateElement($event)" />
      </course-editor-node>
      <add-course-btn v-else @add="updateElement($event)" />
    </div>
  </div>
</template>

<script>
import editors from '@/components/Editor';
import AddCourseBtn from '@/components/utils/AddCourseBtn';
import CourseEditorNode from '../utils/CourseEditorNode.vue';

export default {
  components: {
    ...editors,
    AddCourseBtn,
    CourseEditorNode,
  },
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
  methods: {
    updateElement(e) {
      this.c.content = e;
      this.$emit('update:course', { ...this.c });
    },
    deleteElement() {
      this.c.content = null;
      this.$emit('update:course', { ...this.c });
    },
  },
};
</script>
