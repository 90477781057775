// A component that gather notes of a course for all users

<template>
  {{ course.question }}
  {{ marks }}
</template>

<script>
export default {
  props: ["course", "action"],
  emits: ["marks", "max"],
  data() {
    return {
      users: [],
      responses: {},
      marks: {},
    };
  },
  async mounted() {
    // get all responses
    this.responses = await this.$api.response.get_lasts_by_oid(await this.$oid.compute_course_oid(this.course));
    this.responses = Object.fromEntries(this.responses.map((response) => [response.username, response.content]));

    // get marks
    const marks = Object.fromEntries(
      Object.entries(this.responses).map(([username, response]) => {
        const mark = this.course.correct[response] ? 1 : 0;
        return [username, mark];
      })
    );
    this.marks = marks;

    // emit mark
    this.$emit("marks", marks);
    // emit max
    this.$emit("max", 1);
  }
}
</script>
