// main dummy api object

import course from './course';
import repository from './repository';
import user from './user';
import response from './response';

export default {
  course,
  repository,
  user,
  response,
}
