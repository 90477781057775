<template>
  <div class="column">
    <q-file accept=".jpg, image/*" v-model="files" @update:model-value="uploadFiles($event)" label="Ajouter un fichier"
      fill dense multiple />
    <q-list>
      <!-- list uploaded files oids -->
      <q-item v-for="[i, oid] of m_oids.entries()" :key="oid">
        <q-item-section avatar>
          <q-icon name="delete" @click="removeOid(i)" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ oid }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
export default {
  props: ["oids"],
  emits: ["update:oids"],
  data() {
    return {
      files: [],
      m_oids: this.oids,
    };
  },
  methods: {
    uploadFiles(files) {
      for (const file of files) {
        this.$api.object.putFile(file).then((oid) => {
          this.m_oids.push(oid);
          this.$emit('update:oids', this.m_oids);
        }).catch(error => {
          console.error(error);
        });
      }
    },
    removeOid(i) {
      this.m_oids.splice(i, 1);
      this.$emit('update:oids', this.m_oids);
    },
  },
};
</script>