<template>
  <div class="text-center">
    <img :src="src" :width="course.width" />
  </div>
</template>

<script>
export default {
  props: ["course"],
  data() {
    return {
      src: null,
    };
  },
  methods: {
    fetchImageUrl() {
      // get url from api
      this.$api.object.url(this.course.src).then((url) => {
        this.src = url;
      }).catch(error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.fetchImageUrl();
  },
};
</script>
