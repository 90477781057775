// A component that gather notes of a course for all users

<template>
  {{ responses }}
</template>

<script>
export default {
  props: ["course", "action"],
  emits: ["marks", "max"],
  data() {
    return {
      users: [],
      responses: {},
      marks: {},
    };
  },
  async mounted() {
    // get all responses
    this.responses = await this.$api.response.get_lasts_by_oid(await this.$oid.compute_course_oid(this.course));
    this.responses = Object.fromEntries(this.responses.map((response) => [response.username, response.content]));

    // get marks
    const marks = Object.fromEntries(
      Object.entries(this.responses).map(([username, response]) => {
        let mark = 0;
        // for each question
        for (const [i, q] of this.course.questions.entries()) {
          // get the response
          const r = response[i];
          if (r == null) {
            continue;
          }
          // if the response is correct
          if (q.choices[r].correct) {
            mark += 1;
          }
        }
        return [username, mark];
      })
    );
    this.marks = marks;

    // emit mark
    this.$emit("marks", this.marks);
    // emit max
    this.$emit("max", this.course.questions.length);
  }
}
</script>
