// a dummy course api for testing purpose

let courses = [
  {
    namespace: "admin",
    name: "test",
    ref: "master",
    course: {
      type: "Directory",
      elements: [
        {
          title: "Cours 1",
          content: {
            type: "NumberedElementList",
            oltype: "A",
            elements: [
              {
                title: "Chapitre 1.1",
                content: {
                  type: "Paragraph",
                  text: "Contenu du chapitre 1"
                }
              },
              {
                title: "Chapitre 1.2",
                content: {
                  type: "Paragraph",
                  text: "Contenu du chapitre 2"
                }
              }
            ]
          }
        },
        {
          title: "Cours 2",
          content: {
            type: "Directory",
            elements: [
              {
                title: "Cours 2.1",
                content: {
                  type: "NumberedElementList",
                  oltype: "a",
                  elements: [
                    {
                      title: "Chapitre 2.1.1",
                      content: {
                        "type": "ItemListExercise",
                        "good": [
                          "Faire entrer la lumière"
                        ],
                        "bad": [
                          "Fenêtre"
                        ],
                        "synonyms": {
                          "Faire entrer la lumière": [
                            "Laisser entrer la lumière"
                          ]
                        }
                      }
                    },
                    {
                      title: "Chapitre 2.1.2",
                      content: {
                        type: "Paragraph",
                        text: "Contenu du chapitre 2 😀"
                      }
                    }
                  ]
                }
              },
              {
                title: "Cours 2.2",
                content: {
                  type: "NumberedElementList",
                  elements: [
                    {
                      title: "Chapitre 2.2.1",
                      content: {
                        "type": "YtVideo",
                        "src": "https://www.youtube.com/embed/18iUuweQYbo?start=100&end=110&controls=0&disablekb=1",
                        "title": "La domotique, c’est quoi ???"
                      }
                    },
                    {
                      title: "Chapitre 2.2.2",
                      content: {
                        type: "ElementList",
                        elements: [
                          {
                            type: "HTML",
                            content: "\n        <p>\n          Sur l'image, colorie :\n        </p>\n        <ul>\n          <li style=\"color: blue;\">en bleu les objets qui améliorent l'efficacité des tâches et le confort</li>\n          <li style=\"color: red;\">en rouge ceux qui améliorent la sécurité</li>\n          <li style=\"color: green;\">en vert ceux qui favorisent la communication</li>\n        </ul>\n        <p>\n          <b>Consignes :</b> Clique sur les rectangles pour changer leur couleur. Une fois que tu as terminé, clique\n          sur\n          le bouton \"Vérifier\".\n        </p>"
                          },
                          {
                            type: "QCM",
                            question: "Quels sont les objets qui améliorent l'efficacité des tâches et le confort ?",
                            choices: [
                              "La lampe, car elle éclaire la pièce",
                              "Le téléphone, car il permet de communiquer",
                              "Le réveil, car il permet de se réveiller",
                              "La télévision, car elle permet de regarder des films",
                            ]
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    }
  },
];

export default {
  async get(namespace, name, ref) {
    return courses.find(c => c.namespace === namespace && c.name === name && c.ref === ref).course;
  },
  async put(namespace, name, ref, course) {
    const newEntry = { namespace, name, ref, course };
    if (courses.find(c => c.namespace === namespace && c.name === name && c.ref === ref)) {
      // replace
      courses = courses.map(c => c.namespace === namespace && c.name === name && c.ref === ref ? newEntry : c);
    }
    else {
      // create
      courses.push(newEntry);
    }
  },
}
