<template>
  <q-toolbar elevated class="bg-primary text-white glossy print-hide">
    <q-btn flat size="lg" aria-label="Menu" icon="menu">
      <q-menu persistent v-model="menu">
        <q-list class="col-2 bg-grey-2 print-hide">
          <q-item clickable v-ripple to="/">
            <q-item-section>Home</q-item-section>
          </q-item>
          <q-item v-if="user && user.groups.includes('admin')" clickable v-ripple to="/admin/users">
            <q-item-section>Users</q-item-section>
          </q-item>
          <q-item v-if="user && user.groups.includes('admin')" clickable v-ripple to="/control">
            <q-item-section>Control</q-item-section>
          </q-item>
          <q-item clickable v-ripple @click="menu = false">
            <q-item-section>Close</q-item-section>
            <q-item-section avatar>
              <q-icon name="close" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>

    <q-btn flat size="lg" to="/" class="desktop-only">
      Technologie
    </q-btn>

    <q-space />

    <q-btn-dropdown flat dense icon="account_circle" v-if="user" :label="user.username">
      <q-list>
        <q-item clickable v-close-popup @click="logout">
          <q-item-section>
            <q-item-label>Logout</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>

  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style>
.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0.5em;
  font-size: 1.5em;
}
</style>

<script>
import { ref } from 'vue';

export default {
  name: 'LayoutDefault',
  data() {
    return {
      menu: ref(false),
      user: null,
      repos: [],
    };
  },
  methods: {
    logout() {
      this.$api.user.logout()
        .then(() => {
          this.user = null;
          this.$router.push('/login');
        })
        .catch(error => {
          this.$q.dialog({
            title: error.message,
            message: error.response ? error.response.data.message : "Une erreur est survenue",
            color: 'negative',
          })
        });
    }
  },
  mounted() {
    this.$api.user.me()
      .then(user => {
        this.user = user;
      })
      .catch(error => {
        if (this.$router.currentRoute.value.path == '/login') {
          return;
        }
        if (this.$router.currentRoute.value.path == '/stu/login') {
          return;
        }
        this.$q.dialog({
          title: error.message,
          message: error.response ? error.response.data.message : "Une erreur est survenue",
          color: 'negative',
          persistent: true,
        }).onOk(() => {
          this.$router.push('/login');
        });
      });
  }
};
</script>
