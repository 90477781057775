// a dummy user response api for testing purpose

const responses = [
  {
    username: "admin",
    oid: "2ee86a7fbb424bb4a7d92ed535232111241415091e54c0c9b5e1a45bc5382809",
    submittedAt: "2021-01-01T00:00:00+00:00",
    content: [
      "Fenêtre",
    ]
  },
  {
    username: "admin",
    oid: "2ee86a7fbb424bb4a7d92ed535232111241415091e54c0c9b5e1a45bc5382809",
    submittedAt: "2021-01-01T00:10:00+00:00",
    content: [
      "Faire entrer la lumière",
    ]
  },
]

export default {
  async put(username, oid, response) {
    responses.push({ username: username, oid, submittedAt: new Date().toISOString(), content: response });
  },
  // get all responses for an exercise from a user
  async get_all(username, oid) {
    return responses.filter(r => r.username === username && r.oid === oid).map(r => r.content);
  },
  // get the last response for an exercise from a user
  async get_last(username, oid) {
    return responses.filter(r => r.username === username && r.oid === oid).sort((a, b) => a.submittedAt > b.submittedAt ? -1 : 1)[0].content;
  },
  async debug() {
    return console.log(responses);
  }
}
