<!--
Home page vue component

This page display the home page of the application.
This displays the user name as title, and a list of liks to the different repositories available for the user, with descriptions.
-->

<template>
  <div class="q-my-xl">
    <h3 class="text-center">Bienvenue, {{ username }}</h3>

    <div class="row q-my-md">
      <div class="col-8">
        <!-- search bar to find a repository -->
        <q-input filled dense color="secondary" v-model="search" placeholder="Rechercher un dépôt" />
      </div>
      <div class="col-2"></div>
      <div class="col-2 column" v-if="user && user.groups.includes('prof')">
        <q-btn icon="add" color="primary" to="/repo/create">Créer</q-btn>
      </div>
    </div>

    <q-list class="q-my-md">
      <q-item v-for="repo in repos" :key="repo.namespace + repo.name" v-show="predicate(repo)" clickable v-ripple
        :to="'/repo/' + repo.namespace + '/' + repo.name">
        <q-item-section>
          <q-item-label>{{ repo.namespace }}/{{ repo.name }}</q-item-label>
          <q-item-label caption>{{ repo.description }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: null,
      user: null,
      repos: [],
      search: '',
    };
  },
  methods: {
    predicate(repo) {
      return repo.namespace.includes(this.search) || repo.name.includes(this.search) || repo.description.includes(this.search);
    }
  },
  mounted() {
    this.$api.user.me()
      .then(user => {
        this.username = user.username;
        this.user = user;
      })
      .catch(error => {
        console.error(error);
      });

    this.$api.repository.get_all()
      .then(repos => {
        this.repos = repos;
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>