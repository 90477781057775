<template>
  <div class="column q-gutter-md">
    <div class="row">
      <q-input class="col" v-model="c.question" @update:model-value="$emit('update:course', c)" filled autogrow dense
        type="textarea" />
      <q-toggle class="col-auto" v-model="c.show_question" label="Afficher la question"
        @update:model-value="$emit('update:course', c)" />
    </div>
    <q-input v-model.number="c.answer_lines" filled dense label="Nombre de lignes" type="number"
      @update:model-value="$emit('update:course', c)" />
    <q-input v-model.number="c.max_length" filled dense label="Longueur maximale" type="number"
      @update:model-value="$emit('update:course', c)" />
    <q-checkbox v-model="c.submit_btn" label="Valider" @click="$emit('update:course', c)" />
    <q-checkbox v-model="c.row" label="Afficher en ligne" @click="$emit('update:course', c)" />
    <q-checkbox v-model="c.counter" label="Afficher le compteur" @click="$emit('update:course', c)" />
  </div>
</template>

<script>
export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
};
</script>
