import client from './client';

export default {
  async put(username, oid, response) {
    console.debug(`response.put(${username}, ${oid}, ${response})`);
    client.post(`/api/response/${username}/${oid}`, { content: response });
  },
  // get all responses for an exercise from a user
  async get_all(username, oid) {
    return (await client.get(`/api/response/${username}/${oid}/all`)).map(r => r.content);
  },
  // get the last response for an exercise from a user
  async get_last(username, oid) {
    return (await client.get(`/api/response/${username}/${oid}/last`)).content;
  },
  async get_lasts_by_oid(oid) {
    return (await client.get(`/api/response/*/${oid}/lasts`));
  },
}
