import client from './client';

export default {
  add_user(group, username) {
    return client.get(`/api/group/add/user/${username}`, { params: { group } });
  },
  remove_user(group, username) {
    return client.get(`/api/group/remove/user/${username}`, { params: { group } });
  },
}
