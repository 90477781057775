import { romanize } from "./romanize.js";

function genOlNumber(oltype, i) {
  if (oltype === "1") {
    return i + 1;
  }
  else if (oltype === "a") {
    return "abcdefghijklmnopqrstuvwxyz"[i];
  }
  else if (oltype === "A") {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i];
  }
  else if (oltype === "i") {
    return romanize(i + 1).toLowerCase();
  }
  else if (oltype === "I") {
    return romanize(i + 1);
  }
  else {
    return null;
  }
}

function genOlPrefix(oltype, olsep, i) {
  if (oltype) {
    return genOlNumber(oltype, i) + olsep;
  }
  else {
    return "";
  }
}

export {
  genOlNumber,
  genOlPrefix,
};
