// use axios with VUE_APP_BACKEND_URL to make requests to the backend

import axios from 'axios';

export default {
  async get(url, ...args) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}${url}`, ...args)).data;
  },
  async post(url, ...args) {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}${url}`, ...args)).data;
  },
  async put(url, ...args) {
    return (await axios.put(`${process.env.VUE_APP_BACKEND_URL}${url}`, ...args)).data;
  },
  async delete(url, ...args) {
    return (await axios.delete(`${process.env.VUE_APP_BACKEND_URL}${url}`, ...args)).data;
  },
};