// A component that gather notes of a course for all users

<template>
  <div class="column">
    <course-corrector-node v-for="[i, e] of course.elements.entries()" :key="i" :label="i" :marks="marks[i]"
      :max="max[i]">
      <component :ref="`_${i}`" :is="`${e.type}Corrector`" :course="e"
        @marks="marks[i] = $event; $emit('marks', aggregateMarks())"
        @max="max[i] = $event; $emit('max', aggregateMax())"></component>
    </course-corrector-node>
  </div>
</template>

<script>
import correctors from '@/components/Corrector';
import CourseCorrectorNode from '../utils/CourseCorrectorNode.vue';

export default {
  components: {
    ...correctors,
    CourseCorrectorNode,
  },
  props: ["course", "action"],
  emits: ["marks", "max"],
  data() {
    return {
      marks: [],
      max: [],
    };
  },
  methods: {
    aggregateMarks() {
      let ret = {};
      for (const e of this.marks) {
        if (e) {
          for (const [u, m] of Object.entries(e)) {
            if (m) {
              if (!ret[u]) {
                ret[u] = 0;
              }
              ret[u] += m;
            }
          }
        }
      }
      return ret;
    },
    aggregateMax() {
      return this.max.reduce((a, b) => a + b, 0);
    },
  }
}
</script>
