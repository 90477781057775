<template>
  <q-btn color="secondary" icon="add" label="Ajouter">
    <q-menu fit class="bg-grey-2">
      <q-list>
        <q-item v-if="$clipboard.has()" clickable v-close-popup @click="$emit('add', $clipboard.get())">
          <q-item-section avatar>
            <q-icon name="content_paste" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              Coller {{ $clipboard.get().type }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator v-if="$clipboard.has()" />
        <q-input v-model="search" filled label="Rechercher" />
        <q-scroll-area style="height: 20vh">
          <q-item v-for="type of types" :key="type" v-show="type.toLowerCase().includes(search)" clickable v-close-popup
            @click="$emit('add', { type: type })">
            <q-item-section avatar>
              <q-icon name="add" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ type }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-scroll-area>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import editors from "@/components/Editor";

export default {
  emits: ["add"],
  data() {
    return {
      types: Object.keys(editors).filter((e) => e.includes("Editor")).map((e) => e.replace("Editor", "")),
      search: "",
    };
  },
};
</script>