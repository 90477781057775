<template>
  <div class="q-mx-auto print-hide" :style="{ width: course.width }">
    <q-responsive :ratio="16 / 9">
      <iframe :src="iframe_src" :title="course.title" frameborder="0" referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>
    </q-responsive>
  </div>
  <q-card bordered class="q-pa-sm bg-grey-2 print-only">
    <q-card-section class="row q-gutter-md items-center">
      <q-img class="col-2" :src="img_src">
        <q-icon class="absolute-center" size="xl" name="play_circle_filled" color="white" />
      </q-img>
      <div class="col-auto">
        <div>{{ course.title }}</div>
        <div>{{ link_href }}</div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: ["course"],
  computed: {
    iframe_src() {
      return `https://www.youtube.com/embed/${this.course.id}`;
    },
    img_src() {
      return `https://i1.ytimg.com/vi/${this.course.id}/0.jpg`;
    },
    link_href() {
      return `https://www.youtube.com/watch?v=${this.course.id}`;
    },
  },
};
</script>
