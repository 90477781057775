<template>
  <div class="column q-gutter-md">
    <!-- insert a new first element -->
    <add-course-btn @add="insertElement(0, $event)" />
    <!-- display sub courses -->
    <template v-for="[i, e] of c.elements.entries()" :key="e">
      <q-select v-model="c.col[i]" :options="options" @update:model-value="$emit('update:course', c);" emit-value
        map-options />
      <course-editor-node :label="e.type" @delete="deleteElement(i)" @copy="$clipboard.set(e)"
        @cut="$clipboard.set(e); deleteElement(i)">
        <component :is="e.type + 'Editor'" :course="e" @update:course="updateElement(i, $event)" />
      </course-editor-node>
      <add-course-btn @add="insertElement(i + 1, $event)" />
    </template>
  </div>
</template>

<script>
import editors from '@/components/Editor';
import AddCourseBtn from '@/components/utils/AddCourseBtn';
import CourseEditorNode from '../utils/CourseEditorNode.vue';

export default {
  components: {
    ...editors,
    AddCourseBtn,
    CourseEditorNode,
  },
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
      options: [
        { label: 'col', value: 'col-sm' },
        { label: 'col-1', value: 'col-sm-1' },
        { label: 'col-2', value: 'col-sm-2' },
        { label: 'col-3', value: 'col-sm-3' },
        { label: 'col-4', value: 'col-sm-4' },
        { label: 'col-5', value: 'col-sm-5' },
        { label: 'col-6', value: 'col-sm-6' },
        { label: 'col-7', value: 'col-sm-7' },
        { label: 'col-8', value: 'col-sm-8' },
        { label: 'col-9', value: 'col-sm-9' },
        { label: 'col-10', value: 'col-sm-10' },
        { label: 'col-11', value: 'col-sm-11' },
        { label: 'col-12', value: 'col-sm-12' },
        { label: 'col-auto', value: 'col-sm-auto' },
      ]
    };
  },
  created() {
    this.c.elements = this.c.elements || [];
    this.c.col = this.c.col || [];
  },
  methods: {
    updateElement(i, e) {
      this.c.elements[i] = e;
      this.$emit('update:course', { ...this.c });
    },
    insertElement(i, course) {
      this.c.elements.splice(i, 0, course);
      this.$emit('update:course', { ...this.c });
    },
    deleteElement(i) {
      this.c.elements.splice(i, 1);
      this.$emit('update:course', { ...this.c });
    },
  },
};
</script>
