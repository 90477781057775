<template>
  <div class="column">
    <q-file accept=".jpg, image/*" v-model="file" @update:model-value="uploadImage" label="Ajouter un fichier" />
    <q-input v-model="c.width"></q-input>
    <q-scroll-area style="height: 200px; width: 100%;">
      <div class="text-center">
        <q-img :src="src" :width="c.width" />
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
      src: null,
      file: null,
    };
  },
  mounted() {
    this.fetchImageUrl();
  },
  methods: {
    fetchImageUrl() {
      // get url from api
      this.$api.object.url(this.c.src).then((url) => {
        this.src = url;
      }).catch(error => {
        console.error(error);
      });
    },
    uploadImage(file) {
      this.$api.object.putFile(file).then((oid) => {
        this.c.src = oid;
        this.$emit('update:course', this.c);
        this.fetchImageUrl();
      }).catch(error => {
        console.error(error);
      });
    },
  },
};
</script>
