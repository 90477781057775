// compute git-object-store-like oid from a JSON object

// compute oid for a simple JSON object
async function compute_oid(obj) {
  // need to user browser crypto API
  const encoder = new TextEncoder();
  const data = encoder.encode(JSON.stringify(obj));
  const hash = await crypto.subtle.digest('SHA-256', data);
  // now return a hex digest
  return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}

// transform a course object to replace sub courses by their oid
async function transform_course(obj) {
  return Object.fromEntries(await Promise.all(Object.entries(obj).map(async ([key, value]) => [key, await transform(value)])));
}

// transform a JSON object to replace courses by their oid
async function transform(obj) {
  if (Array.isArray(obj)) {
    // transform all array elements
    return await Promise.all(obj.map(async value => await transform(value)));
  } else if (typeof obj === 'object') {
    // if the object has a type property
    if (Object.hasOwn(obj, 'type')) {
      // return the oid of the course
      return { oid: await compute_course_oid(obj) };
    } else {
      // otherwise, transform all object properties
      return Object.fromEntries(await Promise.all(Object.entries(obj).map(async ([key, value]) => [key, await transform(value)])));
    }
  } else {
    return obj;
  }
}

// compute oid for a course object
async function compute_course_oid(obj) {
  return await compute_oid(await transform_course(obj));
}

export default {
  compute_oid,
  transform_course,
  compute_course_oid,
}
