<template>
  <div class="q-my-lg">
    <h4>{{ namespace }} / {{ name }}</h4>
    <p v-if="repository">
      {{ repository.description }}
    </p>
    <q-card>
      <q-tabs v-model="tab" align="justify" @update:model-value="onTabUpdate"
        v-if="user && user.groups.includes('prof')">
        <q-tab name="view" label="Voir" />
        <q-tab name="edit" label="Editer" />
        <q-tab name="correct" label="Corriger" />
        <q-tab name="settings" label="Configurer" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab">
        <q-tab-panel name="view">
          <div class="column q-gutter-md">
            <q-btn icon="visibility" color="primary" :to="action">
              Visualiser
            </q-btn>
            <component v-if="course" :is="'Course' + course.type" :course="course" :action="action"></component>
          </div>
        </q-tab-panel>
        <q-tab-panel name="edit">
          <div class="column q-gutter-md">
            <q-btn icon="save" color="primary" @click="saveCourse">Sauvegarder</q-btn>
            <course-editor-node v-if="course" :label="course.type" @delete="course = null"
              @copy="$clipboard.set(course)" @cut="$clipboard.set(course); course = null">
              <component :is="course.type + 'Editor'" v-model:course="course" :action="action"></component>
            </course-editor-node>
            <add-course-btn v-else @add="addCourse($event)" />
          </div>
        </q-tab-panel>
        <q-tab-panel name="correct">
          <q-input filled dense color="secondary" v-model="search" placeholder="Rechercher un utilisateur" />
          <course-corrector-node label="Root" :marks="correct_marks" :max="correct_max" :search="search">
            <component v-if="course" :is="`${course.type}Corrector`" :course="course" :action="action"
              @marks="correct_marks = $event" @max="correct_max = $event"></component>
          </course-corrector-node>
        </q-tab-panel>
        <q-tab-panel name="settings">
          <div class="column q-gutter-md">
            <!-- give access to users -->
            <q-input filled dense color="secondary" v-model="search" placeholder="Rechercher un utilisateur" />
            <q-list @vue:mounted="fetchUsers">
              <template v-for="user in users" :key="user.username">
                <q-item v-if="user.username.includes(search)"
                  :class="hasAccess(user) ? ['bg-positive'] : ['bg-negative']">
                  <q-item-section>
                    <q-item-label>
                      {{ user.username }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn v-if="!hasAccess(user)" icon="add" color="positive" @click="addUser(user)" />
                    <q-btn v-else icon="close" color="negative" @click="removeUser(user)" />
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
            <!-- delete -->
            <q-btn icon="delete" color="negative" @click="deleteRepository">Supprimer</q-btn>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
  <div v-if="tab == 'edit'" class="menu column reverse justify-center items-center q-gutter-md print-hide">
    <q-btn size="xl" icon="save" color="primary" @click="saveCourse"></q-btn>
    <!-- remonte en haut de la page -->
    <q-btn size="xl" icon="arrow_upward" color="primary" @click="window.scrollTo(0, 0)"></q-btn>
  </div>
</template>

<style scoped>
.menu {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 0.5em;
}
</style>

<script>
import components from '@/components/Course';  // Map des composants dynamiques
import editors from '@/components/Editor';  // Map des éditeurs dynamiques
import correctors from '@/components/Corrector';  // Map des correcteurs dynamiques
import AddCourseBtn from '@/components/utils/AddCourseBtn';  // Bouton d'ajout de cours
import CourseEditorNode from '../utils/CourseEditorNode.vue';
import CourseCorrectorNode from '../utils/CourseCorrectorNode.vue';

export default {
  components: {
    ...components,
    ...editors,
    ...correctors,
    AddCourseBtn,
    CourseEditorNode,
    CourseCorrectorNode,
  },
  data() {
    return {
      namespace: this.$route.params.namespace,
      name: this.$route.params.name,
      tab: this.$route.query.tab,
      repository: null,
      course: null,
      window: window,
      users: [],
      search: '',
      correct_marks: {},
      correct_max: 0,
      user: null,
    };
  },
  computed: {
    action() {
      return "/course/" + this.namespace + "/" + this.name + "/master";
    },
  },
  methods: {
    loadRepository() {
      this.$api.repository.get(this.namespace, this.name)
        .then(repository => {
          this.repository = repository;
        })
        .catch(error => {
          console.error(error);
          this.repository = null;
        });
    },
    deleteRepository() {
      this.$api.repository.delete(this.namespace, this.name)
        .then(() => {
          this.$router.push("/");
        })
        .catch(error => {
          console.error(error);
        });
    },
    loadCourse() {
      this.$api.course.get(this.namespace, this.name, "master")
        .then(course => {
          this.course = course;
        })
        .catch(error => {
          console.error(error);
          this.course = null;
        });
    },
    saveCourse() {
      this.$api.course.put(this.namespace, this.name, "master", this.course)
        .then(() => {
          console.log(this.course)
        })
        .catch(error => {
          console.error(error);
        });
    },
    addCourse(course) {
      this.course = course;
    },
    onTabUpdate(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    fetchUsers() {
      this.$api.user.get_all()
        .then(users => {
          this.users = users;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addUser(user) {
      this.$api.group.add_user(`GET /api/repository/${this.namespace}/${this.name}`, user.username)
        .catch(error => {
          console.error(error);
        }).then(() => {
          this.$api.group.add_user(`GET /api/course/${this.namespace}/${this.name}/master`, user.username)
            .catch(error => {
              console.error(error);
            });
        });
      user.groups.push(`GET /api/repository/${this.namespace}/${this.name}`);
      user.groups.push(`GET /api/course/${this.namespace}/${this.name}/master`);
    },
    removeUser(user) {
      this.$api.group.remove_user(`GET /api/repository/${this.namespace}/${this.name}`, user.username)
        .catch(error => {
          console.error(error);
        }).then(() => {
          this.$api.group.remove_user(`GET /api/course/${this.namespace}/${this.name}/master`, user.username)
            .catch(error => {
              console.error(error);
            });
        });
      user.groups = user.groups.filter(group => group !== `GET /api/repository/${this.namespace}/${this.name}`);
      user.groups = user.groups.filter(group => group !== `GET /api/course/${this.namespace}/${this.name}/master`);
    },
    hasAccess(user) {
      return user.groups.includes(`GET /api/repository/${this.namespace}/${this.name}`) &&
        user.groups.includes(`GET /api/course/${this.namespace}/${this.name}/master`);
    }
  },
  mounted() {
    this.loadRepository();
    this.loadCourse();
    if (!this.tab) {
      this.tab = "view";
      this.onTabUpdate(this.tab);
    }

    this.$api.user.me()
      .then(user => {
        this.user = user;
      });
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      handler(newName) {
        this.name = newName;
        this.loadRepository();
        this.loadCourse();
      }
    },
    '$route.params.namespace': {
      immediate: true,
      handler(newNamespace) {
        this.namespace = newNamespace;
        this.loadRepository();
        this.loadCourse();
      }
    },
    '$route.query.tab': {
      immediate: true,
      handler(newTab) {
        if (!newTab) {
          this.onTabUpdate("view");
        }
        this.tab = newTab;
      }
    },
  }
};
</script>
