<template>
  <div class="column q-gutter-md">
    <q-input v-model="c.question" @update:model-value="$emit('update:course', c)" filled autogrow dense
      type="textarea" />
    <q-select v-model="c.oltype" @update:model-value="$emit('update:course', c)" filled dense label="Type de liste"
      :options="[null, '1', 'a', 'A', 'i', 'I']" />
    <div class="column">
      <template v-for="[i, choice] of c.choices.entries()" :key="i">
        <div class="row items-center">
          <q-icon class="col-auto" name="delete" @click="c.choices.splice(i, 1); $emit('update:course', c)" />
          <q-input class="col" :model-value="choice" @update:model-value="updateChoice(i, $event)" filled autogrow dense
            type="textarea">
            <template v-slot:prepend>
              {{ getOlPrefix(i) }} <q-icon name="arrow_right" />
            </template>
          </q-input>
          <q-checkbox class="col-auto" v-model="c.correct[i]" label="Correct"
            @update:model-value="$emit('update:course', c)" />
        </div>
      </template>
      <q-btn flat dense icon="add" @click="c.choices.push(''); $emit('update:course', c)" />
    </div>
    <q-checkbox v-model="c.shuffle" label="Mélanger les choix" @click="$emit('update:course', c)" />
    <div class="column">
      <q-checkbox v-model="c.auto_save" label="Sauvegarde Auto" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.auto_correct" label="Correction Auto" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.once" label="Une seule tentative" @click="$emit('update:course', c)" />
    </div>
    <div class="column">
      <q-checkbox v-model="c.submit_btn" label="Bouton de validation" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.submit_correct" label="Correction à la validation" @click="$emit('update:course', c)" />
      <q-checkbox v-model="c.submit_once" label="Valider une seule fois" @click="$emit('update:course', c)" />
    </div>
  </div>
</template>

<script>
import { genOlPrefix } from '@/utils/ol';

export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
  created() {
    if (!this.c.choices) {
      this.c.choices = [''];
    }
    if (!this.c.correct) {
      this.c.correct = [];
    }
  },
  methods: {
    updateChoice(i, value) {
      this.c.choices[i] = value;
      this.$emit('update:course', this.c);
    },
    getOlPrefix(i) {
      return genOlPrefix(this.c.oltype, ". ", i);
    },
  }
};
</script>
