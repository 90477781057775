import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import router from './router';
import axios from 'axios';
import oid from '@/oid';
import cliboard from '@/clipboard';

import dummy_api from './api/dummy';
import backend_api from './api/backend';


axios.defaults.withCredentials = true;

const app = createApp(App);

// Attacher axios à l'instance globale
app.config.globalProperties.$axios = axios;

// Utiliser l'API
if (process.env.VUE_APP_API === 'backend') {
  app.config.globalProperties.$api = backend_api;
}
else {
  app.config.globalProperties.$api = dummy_api;
}

// Utiliser l'API OID
app.config.globalProperties.$oid = oid;

// Utiliser l'API Clipboard
app.config.globalProperties.$clipboard = cliboard;

// Utiliser Quasar avec les plugins nécessaires (ex: Notify)
app.use(Quasar, quasarUserOptions);

app.use(router);
app.mount('#app');
